<template>
  <div data-header="true" class="Home">
    <div
      class="
        d-block d-lg-none
        bg-danger
        d-flex
        justify-content-center
        align-items-center
      "
    >
      <img
        class="p-5"
        :src="require(`@/assets/img/svg/logo-homemobil.svg`)"
        alt=""
      />
    </div>

    <div
      id="beforeFondateur"
      :class="'d-flex flex-column flex-lg-row position-relative'"
    >
      <div class="container p-0 my-5">
        <div class="col-lg-4 px-lg-0 pt-lg-5">
          <TitleTextButton
            :title="true"
            :text="true"
            titleContent="Donnons vie<br>aux initiatives"
            textContent="AZ Initiatys est une société de service dédiée au développement de l'esprit d'entreprendre, de l'innovation et au rapprochement entre les start-ups et les grandes organisations dans leur démarche d'innovation ouverte. La société a été fondée par un entrepreneur, spécialiste du développement des start-ups et de l'open innovation."
          />
        </div>
      </div>

      <div class="d-block d-lg-none multiples-chiffres d-flex flex-wrap">
        <div class="chiffres item bg-danger">
          <p class="chiffre pt-3">+1 000</p>
          <p class="chiffre-info text-center">
            candidats entrepreneurs <br />accompagnés
          </p>
        </div>
        <div class="item photo">
          <img src="../assets/img/img1.png" alt="" />
        </div>
        <div class="item photo">
          <img src="../assets/img/img2.png" alt="" />
        </div>
        <div class="chiffres item bg-danger">
          <p class="chiffre pt-3">10</p>
          <p class="chiffre-info text-center">
            entreprises de croissance <br />dirigées
          </p>
        </div>
        <div class="chiffres item bg-danger">
          <p class="chiffre pt-3">2</p>
          <p class="chiffre-info text-center">
            cellules d'open innovation mutualisées créées
          </p>
        </div>
        <div class="item photo">
          <img src="../assets/img/img4.png" alt="" />
        </div>
      </div>

      <div class="d-none d-lg-block col-lg-8 px-0 pt-5 column_chiffres">
        <div class="d-flex">
          <div data-width="51" class="column"></div>
          <div data-width="28" class="column img z-index9999">
            <img :src="require('../assets/img/img1.png')" alt="" />
          </div>
          <div data-width="21" class="column img z-index9999">
            <img :src="require('../assets/img/img2.png')" alt="" />
          </div>
        </div>

        <div class="d-flex">
          <div data-width="12.5" class="column"></div>
          <div data-width="28" class="column img">
            <img :src="require('../assets/img/img4.png')" alt="" />
          </div>
          <div
            data-width="38"
            class="
              column
              bg-danger
              d-flex
              flex-column
              align-items-center
              justify-content-center
            "
          >
            <p class="chiffre text-white pt-4 m-0">+1 000</p>
            <p class="chiffre-info text-center text-white py-3">
              candidats entrepreneurs<br />accompagnés
            </p>
          </div>
          <div data-width="21" class="column"></div>
        </div>

        <div class="d-flex">
          <div data-width="21.5" class="column"></div>
          <div data-width="38.5" class="column img">
            <img :src="require('../assets/img/img5.png')" alt="" />
          </div>
          <div
            data-width="28"
            class="
              column
              bg-dark
              d-flex
              flex-column
              align-items-center
              justify-content-center
            "
          >
            <p class="chiffre text-white pt-4 m-0">10</p>
            <p class="chiffre-info text-center text-white py-3">
              entreprises de croissance<br />dirigées
            </p>
          </div>
          <div data-width="12" class="column"></div>
        </div>

        <div class="d-flex">
          <div
            data-width="28"
            class="
              column
              bg-danger
              d-flex
              flex-column
              align-items-center
              justify-content-center
            "
          >
            <p class="chiffre text-white pt-4 m-0">2</p>
            <p class="chiffre-info text-center text-white py-3">
              cellules d'open innovation<br />mutualisées créées
            </p>
          </div>
          <div data-width="38.5" class="column img">
            <img :src="require('../assets/img/img3.png')" alt="" />
          </div>
          <div data-width="33.5" class="column"></div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column flex-lg-row container p-lg-0" id="fondateur">
      <img
        class="img-cover container px-0 mt-3 mt-lg-0 col-lg-5 p-lg-0 h-75"
        src="../assets/img/herve1.png"
        alt=""
      />

      <div :class="'container p-3 py-lg-0 col-lg-7 d-flex box-shadow'">
        <div :class="'px-0 my-0 px-lg-5 my-lg-auto'">
          <TitleTextButton
            :other="true"
            :title="true"
            :text="true"
            :link="true"
            otherContent='<p class="subtitle text-dark component-other">Le fondateur</p>'
            titleContent="Hervé Dechene"
            textContent="Hervé Dechene intervient depuis plus de 20 ans auprès des fondateurs d'entreprises innovantes et des dirigeants de grandes organisations pour contribuer au développement des innovations, favoriser des coopérations plus équilibrées, plus rapides et surtout créatrices de valeur."
            linkContent="En savoir plus"
            linkHref="/qui-sommes-nous"
          />
        </div>
      </div>
    </div>

    <div :class="'container p-lg-0 mt-lg-4 mb-5'">
      <p
        class="
          subtitle
          text-uppercase text-dark
          component-other
          text-center
          py-4
        "
      >
        Nos métiers
      </p>
      <div class="d-flex justify-content-center flex-column flex-lg-row">
        <div :class="'col-lg-6 bg-secondary p-4 my-2 p-lg-4 mx-lg-2 my-lg-0'">
          <TitleTextButton
            :title="true"
            :text="true"
            :link="true"
            titleContent="Accompagner, guider et stimuler les entrepreneurs."
            textContent="Depuis 2003, AZ Initiatys transmet la passion d'entreprendre et favorise le partage d'expérience avec ceux qui veulent passer de l'idée à l'action. AZ Initiatys accompagne, guide et stimule les entrepreneurs à chaque étape du développement de leur projet de lancement d'activité ou de création d'entreprise."
            linkContent="En savoir plus"
            linkHref="/espace"
          />
        </div>
        <div :class="'col-lg-6 bg-secondary p-4 my-2 p-lg-4 mx-lg-2 my-lg-0'">
          <TitleTextButton
            :title="true"
            :text="true"
            :link="true"
            titleContent="Professionnaliser la démarche d'open innovation"
            textContent="Depuis 2015, AZ Initiatys conseille, accompagne et transmet son expérience du développement des start-ups aux grandes organisations lors de la création de leur cellule d’innovation ouverte ou de la professionnalisation de leur démarche d'open innovation."
            linkContent="En savoir plus"
            linkHref="/open-innovation"
          />
        </div>
      </div>
    </div>

    <div :class="'d-flex flex-column flex-lg-row container p-lg-0'">
      <img
        :class="'img-cover col-lg-5 p-lg-0 px-0'"
        src="../assets/img/img3x2.png"
        alt=""
      />

      <div :class="'bg-dark pt-4 px-0 py-lg-0 col-lg-7 d-flex box-shadow'">
        <div :class="'container px-lg-5 my-lg-auto'">
          <p :class="'subtitle text-white'">Notre mission</p>
          <h1 class="text-white">
            Donner vie aux initiatives entrepreneuriales.
          </h1>
          <ul class="pl-2 my-4">
            <li class="d-flex">
              <span class="numlist pr-4">1</span>
              <p class="my-auto ml-2 text-white">
                Favoriser et stimuler la diffusion de l'esprit d'entreprendre.
              </p>
            </li>
            <li class="d-flex">
              <span class="numlist pr-4">2</span>
              <p class="my-auto ml-2 text-white">
                Accompagner les entrepreneurs dans le développement pérenne de
                leurs innovations.
              </p>
            </li>
            <li class="d-flex">
              <span class="numlist pr-4">3</span>
              <p :class="'my-auto ml-2 text-white py-2 py-lg-0'">
                Contribuer à la création d'alliances équilibrées, durables et
                créatrices de valeur entre de jeunes entreprises et de grandes
                organisations afin de faciliter l'adoption et la diffusion plus
                rapide des innovations.
              </p>
            </li>
            <li class="d-flex">
              <span class="numlist mt-4 mb-0 pr-4">4</span>
              <p :class="'mt-4 ml-2 text-white py-2 py-lg-0'">
                S'assurer de la bonne compréhension des stratégies, enjeux,
                missions et objectifs de chaque partie lors des négociations
                entre les fondateurs de jeunes entreprises innovantes et les
                dirigeants des grandes organisations.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div :class="'container pt-lg-4 pb-lg-5'">
      <div class="row flex-column flex-lg-row">
        <div :class="'container pt-5 px-lg-2 col-lg-4'">
          <TitleTextButton
            :other="true"
            :title="true"
            :text="true"
            titleContent="Pour qui ?"
            textContent="AZ Initiatys est le trait d'union entre deux univers qui ont des intérêts communs mais qui rencontrent trop souvent des difficultés pour bien se comprendre : les fondateurs d'entreprises innovantes qui souhaitent coopérer avec de grands groupes, les grandes organisations qui mettent en place une démarche d'innovation ouverte pour favoriser le rapprochement avec les start-ups de leur secteur."
          />
        </div>
        <div :class="'container pb-5 pb-lg-0 col-lg-8'">
          <div class="d-flex justify-content-center flex-column flex-lg-row">
            <div
              :class="'card-espacehome bg-secondary p-4 my-3 mx-lg-2 col-lg-5 mt-lg-5 mb-lg-auto'"
            >
              <router-link to="/open-innovation">
                <CardEspaceH
                  titleContent="Innovation<br>ouverte"
                  textContent="Appui à la création et à la professionnalisation des cellules d'innovation ouverte..."
                  imgSrc="cytonn.png"
                />
              </router-link>
            </div>
            <div
              :class="'card-espacehome bg-secondary p-4 my-3 mx-lg-2 col-lg-5 mb-lg-5 mt-lg-auto'"
            >
              <router-link to="/espace">
                <CardEspaceH
                  titleContent="Start-up"
                  textContent="Accompagnement des entrepreneurs dans le développement de leurs projets innovants..."
                  imgSrc="img4.png"
                />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container bg-secondary">
      <div class="py-4 text-center">
        <TitleTextButton
          :title="true"
          :text="true"
          titleContent="Nos participations"
          textContent="AZ Initiatys est membre fondateur d'entreprises qui favorisent le développement des start-ups et des entreprises de croissance."
        />
      </div>
      <div class="row">
        <div class="col-12 col-lg-4 p-0 bkg-img" style="">
          <img :src="require('../assets/img/espaces/pitch.jpg')" alt="" />
          <div class="p-5">
            <img
              src="../assets/img/logopartners/PitchnRise_logo@2x.png"
              alt=""
              class="my-4"
            />
            <p class="d-flex mt-auto text-white">
              Evaluation collaborative et suivi du développement des start-ups.
            </p>
            <a
              class="d-flex text-white"
              style="text-decoration: underline"
              href="https://www.pitchandrise.co"
              target="_blank"
              >accéder au site</a
            >
          </div>
        </div>
        <div class="col-12 col-lg-4 p-0 bkg-img">
          <img :src="require('../assets/img/espaces/spring.jpg')" alt="" />
          <div class="p-5">
            <img
              src="../assets/img/logopartners/spring-project-logo@2x.png"
              alt=""
              class="my-4"
            />
            <p class="d-flex mt-auto text-white">
              Cellule d'innovation ouverte mutualisée de la supply chain.
            </p>
            <a
              class="d-flex text-white"
              style="text-decoration: underline"
              href="https://www.sprint-project.com"
              target="_blank"
              >accéder au site</a
            >
          </div>
        </div>
        <div class="col-12 col-lg-4 p-0 bkg-img">
          <img :src="require('../assets/img/espaces/pairing.jpg')" alt="" />
          <div class="p-5">
            <img
              src="../assets/img/logopartners/pairing_logo@2x.png"
              alt=""
              class="my-4"
            />
            <p class="d-flex mt-auto text-white">
              Prédiction de l'évolution des indicateurs de performance des PME.
            </p>
            <a
              class="d-flex text-white"
              style="text-decoration: underline"
              href="https://www.pairingtechnology.com"
              target="_blank"
              >accéder au site</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleTextButton from "../components/TitleTextButton";
import CardEspaceH from "../components/CardEspaceH";

export default {
  name: "Home",
  components: {
    TitleTextButton,
    CardEspaceH,
  },
  methods: {
    placeFondateur(fondateur, beforeFondateur, colsChiffre) {
      if (document.body.getBoundingClientRect().width > 992) {
        beforeFondateur.style.marginBottom =
          colsChiffre.getBoundingClientRect().bottom -
          fondateur.getBoundingClientRect().top +
          "px";
      } else if (document.body.getBoundingClientRect().width < 992) {
        beforeFondateur.style.marginBottom = "0";
      }
    },
  },
  mounted() {
    const cols = document.querySelectorAll(".column");
    const bkgs = document.querySelectorAll(".bkg-img"); // récupération de toutes les divs ou tu veux appliquer le bkg

    bkgs.forEach((bkg) => {
      // boucle de toutes les divs
      const src = bkg.children[0].getAttribute("src"); // récupération de src de l'img de la div
      bkg.children[0].style.display = "none"; // application du display none sur img
      bkg.style.background = "url('" + src + "') center center no-repeat"; // application du bkg sur la div
      bkg.style.backgroundSize = "cover"; // aplication du cover de bkg sur la div
    });

    cols.forEach((col) => {
      const colWidth = col.getAttribute("data-width");
      if (colWidth !== "auto") {
        col.style.width = colWidth + "%";
      } else {
        col.style.width = colWidth;
      }
      if (col.classList.contains("img")) {
        const src = col.children[0].getAttribute("src");
        col.children[0].style.display = "none";
        col.style.background = "url('" + src + "') center center no-repeat";
        col.style.backgroundSize = "cover";
      }
    });

    const colsChiffre = document.querySelector(".column_chiffres");
    const fondateur = document.getElementById("fondateur");
    const beforeFondateur = document.getElementById("beforeFondateur");
    this.placeFondateur(fondateur, beforeFondateur, colsChiffre);
    window.addEventListener("resize", () => {
      this.placeFondateur(fondateur, beforeFondateur, colsChiffre);
    });
  },
};
</script>

<style scoped lang="scss">
.multiples-chiffres {
  width: 100%;
  .item {
    width: 50%;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
    }

    &.chiffres {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & p {
        color: #fff;
      }
    }
  }
}
.z-index9999 {
  z-index: 9999;
}
.column_chiffres {
  position: absolute;
  right: 0;
  top: -120px;
  .d-flex .column {
    height: 163px;
  }
}
.herve {
  margin-bottom: 18px;
  .title-herve {
    padding-left: 15px;
  }
}
.box-shadow {
  box-shadow: 0px 3px 15px #00000029;
}
// Media Query Desktop (<980px)
@media #{large-up} {
  .multiples-chiffres {
    .item {
      height: 164px;
    }
  }
}

.numlist {
  font-family: "Source Serif Pro", serif;
  font-size: 80px;
  font-weight: 300;
  font-style: italic;
  line-height: 100px;
  color: white;
}
</style>
